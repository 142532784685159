#linechart-tooltip {
  position: fixed;
  text-align: left;
  padding: 7px;
  font-size: 13px;
  font-family: "BuenosAires";
  pointer-events: none;
}

.x-axis-line line {
  display: none;
}

.y-axis-line path {
  display: none;
}

.y-axis-line,
.x-axis-line {
  font-size: 10px;
  font-family: "BuenosAires";
}
